<template>
	<div ref="echartBar" style="width:100%;height:100%;">
	</div>
</template>
<script>
	import echarts from 'echarts'
	export default {
		name: 'echartBar',
		props: {
			echartBarData: {
				type: Array,
				default: []
			},

		},
		data() {
			return {}
		},
		mounted() {
			this.getEchart();
			window.addEventListener('resize', this.resize)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize)
		},
		watch: {
			echartBarData(n, o) {
				this.$nextTick(() => {
					this.getEchart();
				});
			},
		},
		methods: {
			resize(){
				this.myChart.resize()
			},
			getEchart() {
				let dataList = {
					x: [],
					y: []
				}
				this.echartBarData.forEach(t => {
					dataList.x.push(t.name)
					dataList.y.push(t.value)
				})
				this.myChart = echarts.init(this.$refs.echartBar)
				let option = {
					title: {
						text: '支付金额分布图',
						textStyle: {
							color: '#666',
							fontSize: '16'
						}
					},
					tooltip: {
						trigger: 'axis',
					},
					grid: {
						left: '2%',
						top: '15%',
						right: '3%',
						bottom: '4%',
						containLabel: true
					},
					xAxis: {
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						type: 'value',
						axisLabel: {
							show: false
						},
					},
					yAxis: {
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						type: 'category',
						data: dataList.x,
					},
					series: {
						name: '支付笔数',
						type: 'bar',
						label: {
							normal: {
								show: true,
								position: 'right',
								formatter: '{c}笔'
							},
						},
						itemStyle: {
							normal: {
								color: '#60acfc',
								lineStyle: {
									color: '#60acfc'
								}
							}
						},
						stack: '总量',
						smooth: true,
						data: dataList.y
					}
				};
				this.myChart.setOption(option);
			}
		}
	}
</script>
