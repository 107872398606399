<template>
	<div class="main-container">
		<div class="overview">
			<div class="title">
				<div style="margin-top:15px;float:left">交易概况</div>
				<statistics-time style="float:right;" :unShowType='unShowType' :titleShow='false'
				 @getRangeTime='getRangeTime1' :unIndex='1'></statistics-time>
			</div>
			<div class="interview-box">
				<div class="left">
					<div class="interview">
						<div class="item">
							<span class="lable">访客数</span>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UV" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
							<br>
							<span class="num">{{CurrentTradeOverviewItem.UV}}</span>
							<div class="compare">较前{{currentKeyword1}}
								<svg-icon :icon-class="commentArrowsfun(CurrentTradeOverviewItem.UV,PrevTradeOverviewItem.UV)"></svg-icon>
								<span v-if="PrevTradeOverviewItem.UV">{{commentDatafun(CurrentTradeOverviewItem.UV,PrevTradeOverviewItem.UV)}}%</span>
								<span v-if="PrevTradeOverviewItem.UV==0&&CurrentTradeOverviewItem.UV==0">0%</span>
								<span v-if="PrevTradeOverviewItem.UV==0&&CurrentTradeOverviewItem.UV!=0">100%</span>
							</div>
						</div>
					</div>
					<div class="interview bug">
						<div class="item">
							<span class="lable">下单人数</span>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.SubmitOrderMemberCount" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
							<br>
							<span class="num">{{CurrentTradeOverviewItem.SubmitOrderMemberCount}}</span>
							<div class="compare">较前{{currentKeyword1}}
								<svg-icon :icon-class="commentArrowsfun(CurrentTradeOverviewItem.SubmitOrderMemberCount,PrevTradeOverviewItem.SubmitOrderMemberCount)"></svg-icon>
								<span v-if="PrevTradeOverviewItem.SubmitOrderMemberCount">{{commentDatafun(CurrentTradeOverviewItem.SubmitOrderMemberCount,PrevTradeOverviewItem.SubmitOrderMemberCount)}}%</span>
								<span v-if="PrevTradeOverviewItem.SubmitOrderMemberCount==0&&CurrentTradeOverviewItem.SubmitOrderMemberCount==0">0%</span>
								<span v-if="PrevTradeOverviewItem.SubmitOrderMemberCount==0&&CurrentTradeOverviewItem.SubmitOrderMemberCount!=0">100%</span>

							</div>
						</div>
						<div class="item">
							<span class="lable">下单笔数</span>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.SubmitOrderCount" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
							<br>
							<span class="num">{{CurrentTradeOverviewItem.SubmitOrderCount}}</span>
							<div class="compare">较前{{currentKeyword1}}
								<svg-icon :icon-class="commentArrowsfun(CurrentTradeOverviewItem.SubmitOrderCount,PrevTradeOverviewItem.SubmitOrderCount)"></svg-icon>
								<span v-if="PrevTradeOverviewItem.SubmitOrderCount">{{commentDatafun(CurrentTradeOverviewItem.SubmitOrderCount,PrevTradeOverviewItem.SubmitOrderCount)}}%</span>
								<span v-if="PrevTradeOverviewItem.SubmitOrderCount==0&&CurrentTradeOverviewItem.SubmitOrderCount==0">0%</span>
								<span v-if="PrevTradeOverviewItem.SubmitOrderCount==0&&CurrentTradeOverviewItem.SubmitOrderCount!=0">100%</span>

							</div>
						</div>
						<div class="item">
							<span class="lable">下单金额</span>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.SubmitOrderMoney" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
							<br>
							<span class="num">{{CurrentTradeOverviewItem.SubmitOrderMoney}}</span>
							<div class="compare">较前{{currentKeyword1}}
								<svg-icon :icon-class="commentArrowsfun(CurrentTradeOverviewItem.SubmitOrderMoney,PrevTradeOverviewItem.SubmitOrderMoney)"></svg-icon>
								<span v-if="PrevTradeOverviewItem.SubmitOrderMoney">{{commentDatafun(CurrentTradeOverviewItem.SubmitOrderMoney,PrevTradeOverviewItem.SubmitOrderMoney)}}%</span>
								<span v-if="PrevTradeOverviewItem.SubmitOrderMoney==0&&CurrentTradeOverviewItem.SubmitOrderMoney==0">0%</span>
								<span v-if="PrevTradeOverviewItem.SubmitOrderMoney==0&&CurrentTradeOverviewItem.SubmitOrderMoney!=0">100%</span>
							</div>
						</div>
					</div>
					<div class="interview flow">
						<div class="item">
							<span class="lable">支付人数</span>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.PayOrderMemberCount" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
							<br>
							<span class="num">{{CurrentTradeOverviewItem.PayOrderMemberCount}}</span>
							<div class="compare">较前{{currentKeyword1}}
								<svg-icon :icon-class="commentArrowsfun(CurrentTradeOverviewItem.PayOrderMemberCount,PrevTradeOverviewItem.PayOrderMemberCount)"></svg-icon>
								<span v-if="PrevTradeOverviewItem.PayOrderMemberCount">{{commentDatafun(CurrentTradeOverviewItem.PayOrderMemberCount,PrevTradeOverviewItem.PayOrderMemberCount)}}%</span>
								<span v-if="PrevTradeOverviewItem.PayOrderMemberCount==0&&CurrentTradeOverviewItem.PayOrderMemberCount==0">0%</span>
								<span v-if="PrevTradeOverviewItem.PayOrderMemberCount==0&&CurrentTradeOverviewItem.PayOrderMemberCount!=0">100%</span>
							</div>
						</div>
						<div class="item">
							<span class="lable">支付笔数</span>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.PayOrderCount" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
							<br>
							<span class="num">{{CurrentTradeOverviewItem.PayOrderCount}}</span>
							<div class="compare">较前{{currentKeyword1}}
								<svg-icon :icon-class="commentArrowsfun(CurrentTradeOverviewItem.PayOrderCount ,PrevTradeOverviewItem.PayOrderCount)"></svg-icon>
								<span v-if="PrevTradeOverviewItem.PayOrderCount">{{commentDatafun(CurrentTradeOverviewItem.PayOrderCount ,PrevTradeOverviewItem.PayOrderCount)}}%</span>
								<span v-if="PrevTradeOverviewItem.PayOrderCount==0&&CurrentTradeOverviewItem.PayOrderCount==0">0%</span>
								<span v-if="PrevTradeOverviewItem.PayOrderCount==0&&CurrentTradeOverviewItem.PayOrderCount!=0">100%</span>
							</div>
						</div>
						<div class="item">
							<span class="lable">支付金额</span>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.PayOrderMoney" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
							<br>
							<span class="num">{{CurrentTradeOverviewItem.PayOrderMoney}}</span>
							<div class="compare">较前{{currentKeyword1}}
								<svg-icon :icon-class="commentArrowsfun(CurrentTradeOverviewItem.PayOrderMoney,PrevTradeOverviewItem.PayOrderMoney)"></svg-icon>
								<span v-if="PrevTradeOverviewItem.PayOrderMoney">{{commentDatafun(CurrentTradeOverviewItem.PayOrderMoney,PrevTradeOverviewItem.PayOrderMoney)}}%</span>
								<span v-if="PrevTradeOverviewItem.PayOrderMoney==0&&CurrentTradeOverviewItem.PayOrderMoney==0">0%</span>
								<span v-if="PrevTradeOverviewItem.PayOrderMoney==0&&CurrentTradeOverviewItem.PayOrderMoney!=0">100%</span>
							</div>
						</div>
						<div class="item">
							<span class="lable">客单价</span>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.CustomerUnitPrice" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
							<br>
							<span class="num">{{CurrentTradeOverviewItem.CustomerUnitPrice}}</span>
							<div class="compare">较前{{currentKeyword1}}
								<svg-icon :icon-class="commentArrowsfun(CurrentTradeOverviewItem.CustomerUnitPrice,PrevTradeOverviewItem.CustomerUnitPrice)"></svg-icon>
								<span v-if="PrevTradeOverviewItem.CustomerUnitPrice">{{commentDatafun(CurrentTradeOverviewItem.CustomerUnitPrice,PrevTradeOverviewItem.CustomerUnitPrice)}}%</span>
								<span v-if="PrevTradeOverviewItem.CustomerUnitPrice==0&&CurrentTradeOverviewItem.CustomerUnitPrice==0">0%</span>
								<span v-if="PrevTradeOverviewItem.CustomerUnitPrice==0&&CurrentTradeOverviewItem.CustomerUnitPrice!=0">100%</span>
							</div>
						</div>
					</div>
				</div>
				<div class="right">
					<img class="bg-flow" src="http://xscdn2017.oss-cn-hangzhou.aliyuncs.com/images/melyshop/PC-businessOverview.png " />
					<div class="rate rate-one">
						转化率
						<br>
						{{CurrentTradeOverviewItem.UVAndSubmitOrderMemberCountConversionRate}}%
					</div>
					<div class="rate rate-two">
						转化率
						<br>
						{{CurrentTradeOverviewItem.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate}}%
					</div>
					<div class="rate rate-three">
						转化率
						<br>
						{{CurrentTradeOverviewItem.UVAndPayOrderMemberCountConversionRate}}%
					</div>
				</div>
			</div>
			<div class="echart-box">
				<!-- :lineDateData='lineDateData1' -->
				<echart-line :echartLineData='echartLineData' :lineXAxisData='lineXAxisData' :lineDateData='lineDateData1'></echart-line>
			</div>
		</div>

		<div class="constitute">
			<div class="title">
				<div style="margin-top:15px;float:left">
					交易构成
					
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
            <div v-html='toBreak(checkTooltip.busyComposition)' slot='content'></div>
						<i class="el-icon-warning icon"></i>
					</el-tooltip>
				</div>
				<option-month style="display:inline-block;float:right" @getRangeTime='getRangeMonth1'></option-month>
			</div>
			<div class="echart-box">
				<div class="left">
					<echart-pie :echartPieData='echartPieData'></echart-pie>
				</div>
				<div class="right">
					<el-table :data="ConstituteList" border>
						<el-table-column prop="Name"></el-table-column>
						<el-table-column label="支付人数">
							<template slot-scope="scope">
								<div>{{scope.row.Paycount}}</div>
								<div style="color:#999;">
									较上一月
									<svg-icon :icon-class="commentArrowsfun(scope.row.Paycount,scope.row.lastPaycount)"></svg-icon>
									<span v-if="scope.row.lastPaycount">{{commentDatafun(scope.row.Paycount,scope.row.lastPaycount)}}%</span>
									<span v-if="scope.row.lastPaycount==0&&scope.row.Paycount==0">0%</span>
									<span v-if="scope.row.lastPaycount==0&&scope.row.Paycount!=0">100%</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="支付笔数" prop="">
							<template slot-scope="scope">
								<div>{{scope.row.PayNum}}</div>
								<div style="color:#999;">较上一月
									<svg-icon :icon-class="commentArrowsfun(scope.row.PayNum,scope.row.lastPayNum)"></svg-icon>
									<span v-if="scope.row.lastPayNum">{{commentDatafun(scope.row.PayNum,scope.row.lastPayNum)}}%</span>
									<span v-if="scope.row.lastPayNum==0&&scope.row.PayNum==0">0%</span>
									<span v-if="scope.row.lastPayNum==0&&scope.row.PayNum!=0">100%</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="支付金额（元）" prop="">
							<template slot-scope="scope">
								<div>{{scope.row.PayMoney}}</div>
								<div style="color:#999;">较上一月
									<svg-icon :icon-class="commentArrowsfun(scope.row.PayMoney,scope.row.lastPayMoney)"></svg-icon>
									<span v-if="scope.row.lastPayMoney">{{commentDatafun(scope.row.PayMoney,scope.row.lastPayMoney)}}%</span>
									<span v-if="scope.row.lastPayMoney==0&&scope.row.PayMoney==0">0%</span>
									<span v-if="scope.row.lastPayMoney==0&&scope.row.PayMoney!=0">100%</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="echart-box bar">
				<echart-bar :echartBarData='echartBarData'></echart-bar>
			</div>
		</div>

		<div class="area">
			<div class="title">
				<div style="margin-top:15px;float:left">
					地域分布
					<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.areaComposition" placement="top-start">
						<i class="el-icon-warning icon"></i>
					</el-tooltip>
				</div>
				<option-month style="display:inline-block;float:right" @getRangeTime='getRangeMonth2'></option-month>
			</div>
			<!-- style="height: 510px;" -->
			<div class="echart-map" style="display: flex;">
				<div style="height: 510px;width:400px;margin-right:15px;">
					<echart-map :echartMapData='echartMapData'></echart-map>
				</div>
				<div>
					<el-table :data="AreaListdata" border style="width: 500px;">
						<el-table-column label="Top省份" prop="Province"></el-table-column>
						<el-table-column label="支付金额" prop="PayOrderMoney"></el-table-column>
						<el-table-column label="支付人数" prop="PayOrderMemberCount"></el-table-column>
						<el-table-column label="支付笔数" prop="PayOrderCount"></el-table-column>
						<el-table-column label="访客数" prop="UV"></el-table-column>
						<el-table-column label="访问-支付转化率" prop="Rate">
							<template slot-scope="scope">
								<div>{{scope.row.Rate + '%'}}</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>

		<!-- <div class="tag">
			<div class="title">
				<div style="margin-top:15px;float:left">标签成交分析</div>
				<statistics-time style="display:inline-block;float:right" :unShowType='unShowType2' :titleShow='false'
				 @getRangeTime='getRangeTime4'></statistics-time>
			</div>
			<div class="table">
				<el-table :data="TagtableList">
					<el-table-column label="标签" prop="TagName"></el-table-column>
					<el-table-column label="支付笔数" prop="PayOrderCount"></el-table-column>
					<el-table-column label="支付金额（元）" prop="PayOrderMoney"></el-table-column>
					<el-table-column label="支付人数" prop="PayOrderMemberCount"></el-table-column>
				</el-table>
			</div>
		</div> -->


	</div>
</template>
<script>
	import statisticsTime from '../StatisticsTime'
	import echartLine from './echartLine'
	import echartPie from './echartPie'
	import echartBar from './echartBar'
	import echartMap from './echartMap'
	import config from '@/config/index'
	import optionMonth from './optionMonth'
	import {
		dataTransSurvey,
		dataTransTrade,
		AreaDistributdata,
		Constitutedata,
		payMoneybarData,
		tagAnalyzeData
	} from '@/api/TurnTomySelf'
	export default {
		components: {
			statisticsTime,
			echartLine,
			echartPie,
			echartBar,
			echartMap,
			optionMonth
		},
		data() {
			return {
				lineDateData1:[],
				TagtableList: [],
				CurrentTradeOverviewItem: {},
				checkTooltip:{
					UV: '统计筛选时间内，所有商城页面被访问的人数，人数去重，1人访问多次计1人',
					SubmitOrderMemberCount: '统计筛选时间内，成功提交订单的人数，1人下单成功多次，计1人',
					SubmitOrderCount: '统计筛选时间内，成功下单的订单数量',
					SubmitOrderMoney: '统计筛选时间内，成功下单的订单金额',
					PayOrderMemberCount: '计筛选时间内，支付成功的人数；1人支付成功多次，计1人',
					PayOrderCount: '统计筛选时间内，支付成功的订单数量',
					PayOrderMoney: '统计筛选时间内，支付成功的订单，订单金额之和',
					CustomerUnitPrice: '统计筛选时间内，支付金额/支付人数',
					busyComposition: '新成交客户：在筛选时间之前没有支付成功过订单，在筛选时间内支付成功订单的客户\n老成交客户：在筛选时间之前有支付成功过订单，在筛选时间内再次支付成功订单的客户',
					areaComposition: '筛选时间内，根据客户支付时候的ip地址进行统计，如果一个客户在筛选时间内通过多个不同省份的ip地址进行支付，会同时计入多个省份',
				},
				PrevTradeOverviewItem: {},
				currentKeyword1: '一日',
				currentKeyword2: '一日',
				timeKeywords: ['一日', '一周', '一月', '7天', '30天'],
				unShowType: [0, 6, 7, 8, 9],
				startDate1: '',
				endDate1: '',
				startLineTime1: '',
				endLineTime1: '',
				timeType1: 1,
				startDate2: '',
				endDate2: '',
				timeType2: 1,
				startDate3: '',
				endDate3: '',
				timeType3: 1,
				startDate4: '',
				endDate4: '',
				timeType4: 1,
				unShowType2: [0, 2, 4, 5, 7, 8, 9],
				lineXAxisData: [],
				echartLineData: [],
				echartPieData: [{
					name: '新成交客户',
					value: 0
				}, {
					name: '老成交客户',
					value: 0
				}],
				ConstituteList: [{
						Name: '新成交客户',
						Paycount: 0,
						PayNum: 0,
						PayMoney: 0,
						lastPaycount: 0,
						lastPayNum: 0,
						lastPayMoney: 0,

					},
					{
						Name: '老成交客户',
						Paycount: 0,
						PayNum: 0,
						PayMoney: 0,
						lastPaycount: 0,
						lastPayNum: 0,
						lastPayMoney: 0,
					}
				],
				Initdata: [{
					StartOrderMoney: 0,
					EndOrderMoney: 50,
					OrderCount: 100,
					IsMax: false
				}, {
					StartOrderMoney: 50,
					EndOrderMoney: 100,
					OrderCount: 200,
					IsMax: false
				}, {
					StartOrderMoney: 100,
					EndOrderMoney: 150,
					OrderCount: 300,
					IsMax: false
				}, {
					StartOrderMoney: 150,
					EndOrderMoney: 200,
					OrderCount: 400,
					IsMax: false
				}, {
					StartOrderMoney: 200,
					EndOrderMoney: 0,
					OrderCount: 500,
					IsMax: true
				}],
				echartBarData: [],
				//地域分布图数据
				echartMapData: [],
				AreaListdata: [],

				page: {
					size: 20,
					total: 0,
					current: 1
				},
				groupDate:[],
			}
		},
		created() {

		},
		methods: {
			toBreak(val) {
				return val.split('\n').join('<br>')
			},
			commentDatafun(CurrentData, PrevData) {
				return (Math.abs(CurrentData - PrevData) / PrevData * 100).toFixed(2)
			},
			// 公用的箭头方法
			commentArrowsfun(CurrentData, PrevData) {
				let upOrDown = ""
				if (CurrentData != PrevData) {
					if (CurrentData > PrevData) {
						upOrDown = 'gorise'
					} else {
						upOrDown = 'decline'
					}
				} else {
					upOrDown = ''
				}
				return upOrDown
			},
			async getInitopData() {
				try {
					let data = {
						TimeType: this.timeType1,
						StartDate: this.startDate1,
						EndDate: this.endDate1
					}
					let result = await dataTransSurvey(data)
					if (result.IsSuccess) {
						this.CurrentTradeOverviewItem = result.Result.CurrentTradeOverviewItem
						this.PrevTradeOverviewItem = result.Result.PrevTradeOverviewItem
					}
				} catch (err) {

				} finally {

				}
			},
			getRangeTime1(startDate, endDate, timeType, startLineTime, endLineTime, groupDate) {
				console.log(groupDate,'这个是啥子')
				this.startDate1 = startDate
				this.endDate1 = endDate
				this.timeType1 = timeType
				this.currentKeyword1 = this.timeKeywords[timeType - 1]
				//往前推的开始时间和结束时间
				this.startLineTime1 = startLineTime
				this.endLineTime1 = endLineTime
				this.lineXAxisData = groupDate
				// this.groupDate = groupDate
				// console.log(groupDate,'日期数据88888888')
				// this.lineXAxisData = groupDate
				//概况
				this.getInitopData()
				//趋势图
				this.getopTrendata()
			},
			async getopTrendata() {
				try {
					let data = {
						TimeType: this.timeType1,
						StartDate: this.startLineTime1,
						EndDate: this.endLineTime1
					}
					let result = await dataTransTrade(data)
					let dates = []
					let PayOrderMoneys = []
					let PayOrderMemberCounts = []
					let PayOrderCounts = []
					let UVAndSubmitOrderMemberCountConversionRates = []
					let SubmitOrderMemberCountAndPayOrderMemberCountConversionRates = []
					let UVAndPayOrderMemberCountConversionRates = []
					result.Result.map(item => {
						dates.push(item.StatisStartDate.split(' ')[0])
						PayOrderMoneys.push(item.PayOrderMoney)
						PayOrderMemberCounts.push(item.PayOrderMemberCount)
						PayOrderCounts.push(item.PayOrderCount)
						UVAndSubmitOrderMemberCountConversionRates.push(item.UVAndSubmitOrderMemberCountConversionRate)
						SubmitOrderMemberCountAndPayOrderMemberCountConversionRates.push(item.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate)
						UVAndPayOrderMemberCountConversionRates.push(item.UVAndPayOrderMemberCountConversionRate)
					})
					// this.lineXAxisData = dates
					this.echartLineData = [{
							name: '支付金额',
							value: PayOrderMoneys
						},
						{
							name: '支付人数',
							value: PayOrderMemberCounts
						},
						{
							name: '支付笔数',
							value: PayOrderCounts
						},
						{
							name: '访问-下单转化率',
							value: UVAndSubmitOrderMemberCountConversionRates
						},
						{
							name: '下单-支付转化率',
							value: SubmitOrderMemberCountAndPayOrderMemberCountConversionRates
						},
						{
							name: '访问-支付转化率',
							value: UVAndPayOrderMemberCountConversionRates
						}
					]
					
					// console.log('实质性的这里吗')
					this.lineDateData1 = []
					
					if(this.timeType1 == 2){
						console.log(result.Result,'具体的结果')
						result.Result.forEach(t=>{
							let start = t.StatisStartDate.trim().split(" ")[0]
							let end = t.StatisEndDate.trim().split(" ")[0]
							let item = start+'~'+end
							this.lineDateData1.push(item)
						})
					}
					console.log(this.lineDateData1,'这个是周的具体日期吗')
				} catch (err) {

				} finally {

				}
			},
			//新老客户分析
			getRangeMonth1(startDate, endDate, timeType) {
				this.startDate2 = startDate
				this.endDate2 = endDate
				this.timeType2 = timeType
				this.currentKeyword2 = this.timeKeywords[timeType - 1]
				this.getConstitutedata()
				//支付金额分布图
				this.getpayMoneybar()
			},
			async getConstitutedata() {
				try {
					let data = {
						StartTime: this.startDate2
					}
					let result = await Constitutedata(data)
					if (result.IsSuccess) {
						this.ConstituteList = [{
								Name: '新成交客户',
								Paycount: result.Result.NewPayOrderMemberCount,
								PayNum: result.Result.NewPayOrderCount,
								PayMoney: result.Result.NewPayOrderMoney,
								lastPaycount: result.Result.LastNewPayOrderMemberCount,
								lastPayNum: result.Result.LastNewPayOrderCount,
								lastPayMoney: result.Result.LastNewPayOrderMoney,

							},
							{
								Name: '老成交客户',
								Paycount: result.Result.OldPayOrderMemberCount,
								PayNum: result.Result.OldPayOrderCount,
								PayMoney: result.Result.OldPayOrderMoney,
								lastPaycount: result.Result.LastOldPayOrderMemberCount,
								lastPayNum: result.Result.LastOldPayOrderCount,
								lastPayMoney: result.Result.LastOldPayOrderMoney,
							}
						]

						this.echartPieData = [{
							name: '新成交客户',
							value: result.Result.NewPayOrderMemberCount
						}, {
							name: '老成交客户',
							value: result.Result.OldPayOrderMemberCount
						}]
					}
				} catch (err) {

				} finally {

				}
			},
			//获取分布图数据
			async getpayMoneybar() {
				try {
					let data = {
						Time: this.startDate2
					}
					let result = await payMoneybarData(data)
					this.echartBarData = []
					result.Result.map(item => {
						let obj = {}
						if (!item.IsMax) {
							obj.name = item.StartOrderMoney + '-' + item.EndOrderMoney + '元'
						
						} else {
							obj.name = item.StartOrderMoney + '元以上'
						}
						obj.value = item.OrderCount
						this.echartBarData.push(obj)
					})
					
				} catch (err) {

				} finally {

				}
			},
			getRangeMonth2(startDate, endDate, timeType) {
				this.startDate3 = startDate
				this.endDate3 = endDate
				this.timeType3 = timeType
				this.getAreaDistributdata()
			},
			async getAreaDistributdata() {
				try {
					let data = {
						TimeType: this.timeType3,
						StartTime: this.startDate3
					}
					let result = await AreaDistributdata(data)
					
					this.AreaListdata = result.Result.slice(0,10)
					console.log(this.AreaListdata,'表格返回的数据')
					this.AreaListdata.map(item =>{
						if (!item.Province) {
							item.Province = '其他'
						}
						return item
					})
					this.echartMapData = []
					result.Result.map(item => {
						let obj = {}
						obj.name = item.Province
						obj.value = item.UV

						this.echartMapData.push(obj)
					})
					
					// console
					this.echartMapData.map(item => {
						if(!item.name){
							item.name = '其他'
						}
						if (item.name == '上海市') {
							item.name = '上海'
						}
						if (item.name == '北京市') {
							item.name = '北京'
						}
						if(item.name == '宁夏回族自治区'){
							item.name = '宁夏'
						}
						return item
					})
					console.log(this.echartMapData, '地图的数据怎么回事儿')
				} catch (err) {

				} finally {

				}
			},
			getRangeTime4(startDate, endDate, timeType) {
				this.startDate4 = startDate
				this.endDate4 = endDate
				this.timeType4 = timeType
				//获取标签数据
				this.getTagAnalyzeData()
			},
			async getTagAnalyzeData() {
				try {
					let data = {
						TimeType: this.timeType4,
						StartTime: this.startDate4,
						EndTime: this.endDate4
					}
					let result = await tagAnalyzeData(data)
					if (result.IsSuccess) {
						this.TagtableList = result.Result
					}
				} catch (err) {

				} finally {

				}
			},
			handleSizeChange(val) {
				this.page.size = val
			},
			handleCurrentChange(val) {
				this.page.current = val
			}
		}
	}
</script>
<style lang="less" scoped>
	.main-container {
		.title {
			font-size: 20px;
			margin-bottom: 10px;
			overflow: hidden;
			position: relative;

			.right {
				float: right;
				margin-right: 5px;
			}
		}

		.icon {
			color: #999;
			margin-left: 5px;
			cursor: pointer;
		}

		.overview {
			padding: 0 15px 15px 15px;
			background-color: #fff;
			margin-bottom: 10px;

			.interview-box {
				display: flex;
				flex-direction: row;

				// position: relative;
				.left {
					width: calc(100% - 300px);

					.interview {
						// width: 90%;
						background-color: rgb(225, 238, 251);
						overflow: hidden;
						display: flex;
						flex-direction: row;

						.item {
							padding: 25px;
							display: inline-block;
							width: 25%;
						}

						.item:nth-child(5n) {
							word-wrap: break-word;
							word-break: normal;
						}

						.lable {
							line-height: 20px;
							font-size: 12px;
						}

						.icon {
							font-size: 14px;
							color: #999;
							margin-left: 5px;
							cursor: pointer;
						}

						.num {
							line-height: 30px;
							font-size: 18px;
						}

						.compare {
							line-height: 20px;
							font-size: 12px;
						}

						.compare-hidden {
							display: none;
						}
					}

					.bug {
						margin-top: 5px;
						background-color: rgb(225, 230, 251);
					}

					.flow {
						margin-top: 5px;
						background-color: rgb(225, 251, 231);
					}
				}

				.right {
					position: relative;

					.bg-flow {
						margin-left: -30px;
						width: 300px;
						height: 370px;
						object-fit: contain;
					}

					.rate {
						color: #606266;
						line-height: 20px;
						font-size: 14px;
						position: absolute;
					}

					.rate-one {
						top: 110px;
						left: 150px;
					}

					.rate-two {
						top: 220px;
						left: 140px;
					}

					.rate-three {
						top: 170px;
						left: 220px;
					}
				}

			}

			.echart-box {
				border: 1px solid #ddd;
				padding: 10px;
				width: 100%;
				height: 500px;
				margin-top: 10px;
				background-color: #fff;
			}
		}

		.constitute {
			padding: 0 15px 15px 15px;
			background-color: #fff;
			margin-bottom: 10px;

			.echart-box {
				border: 1px solid #ddd;
				padding: 10px;
				width: 100%;
				height: 300px;
				margin-top: 10px;
				background-color: #fff;
				position: relative;
				overflow: hidden;

				.left {
					width: 30%;
					height: 100%;
					float: left;
				}

				.right {
					height: 100%;
					overflow: hidden;
					width: 70%;
					float: right;
					margin-top: 80px;
				}
			}

			.bar {
				height: 500px;
				overflow: hidden;
			}

		}

		.area {
			padding: 0 15px 15px 15px;
			background-color: #fff;
			margin-bottom: 10px;

			.echart-map {
				border: 1px solid #ddd;
				padding: 10px;
				width: 100%;
				// height: 510px;
				margin-top: 10px;
				background-color: #fff;

				.map-left {
					width: 40%;
					height: 100%;
					float: left;
				}

				.map-right {
					height: 100%;
					overflow: hidden;
					width: 60%;
					float: right;
				}
			}
		}

		.tag {
			padding: 0 15px 15px 15px;
			background-color: #fff;
			margin-bottom: 10px;

			.table {
				position: relative;
				overflow: hidden;
			}
		}

	}
</style>
