<template>
  <div class="top">
    <el-select style="margin:0 10px;width:110px;" v-model="timeType" placeholder="请选择" @change='changeTimeOption'>
      <el-option
        v-for="item in timeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
    <el-date-picker
      v-if="timeType==3"
      v-model="Monthvalue"
      format="yyyy-MM"
      type="month"
      @change="ChangeMonthvalue"
      :picker-options="pickerOptionsMonth"
      placeholder="选择月">
    </el-date-picker>
  </div>
</template>
<script>
export default {
  name:'optionMonth',
  props:{
  },
  data(){
    return {
      startLineTime:'',
      endLineTime:'',
      Monthvalue: '',
      Startdate: '',
      EndDate: '',
      pickerOptionsMonth: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 24 * 30 * 60 * 60 * 1000
        }
      },
      timeType:3,
      timeOptions:[
        {
          value:3,
          label:'自然月'
        }
      ],
    }
  },
  created() {
    this.initDefaultType()
  }, 
  methods:{
    initDefaultType(){
        let nowdays = new Date();  
        let year = nowdays.getFullYear();  
        let month = nowdays.getMonth();  
        if(month==0)  
        {  
            month=12;  
            year=year-1;  
        }  
        if (month < 10) {  
            month = "0" + month;  
        }  
        let firstDay = new Date(year + "-" + month + "-" + "01");//上个月的第一天  
        this.getMonthDate(firstDay)
    },
    //改变月
   getMonthDate(date) {
      let time = new Date(date);
      let year = time.getFullYear();
      let month = (time.getMonth()+1) < 10 ? '0' + (time.getMonth()+1) :( time.getMonth()+1);
      //上月第一天
      this.Startdate = this.formatDate(date)
      //上月最后一天
      let myDate = new Date(year,month,0);
      this.Monthvalue = this.EndDate = this.endLineTime = year+'-'+ month + '-'+ myDate.getDate()

      //获取过去12个月
      let dateBefore = new Date(time.setMonth( time.getMonth()-11))
      let yearBefore = dateBefore.getFullYear();
      let monthBefore = (dateBefore.getMonth()+1) < 10 ? '0' + (dateBefore.getMonth()+1) :( dateBefore.getMonth()+1);
      this.startLineTime = yearBefore + '-' + monthBefore + '-' +'01'
      // ,this.startLineTime,this.endLineTime
      this.$emit('getRangeTime',this.Startdate,this.EndDate,this.timeType)
    },
    //初始化
    getData(){

    },
    //改变时间段
    changeTimeOption(e){
      if(e==3){
        this.initDefaultType()
      }
    },
    formatTen(num) {
      return num > 9 ? (num + "") : ("0" + num);
    },
    //格式化时间 YYYY-MM-DD/ YYYY-MM-DD hh:mm:ss
    formatDate(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let h = date.getHours();
      //获取当前分钟数(0-59)
      let m = date.getMinutes();
      //获取当前秒数(0-59)
      let s = date.getSeconds();
      if(this.timeType == 4){
        return year + "-" + this.formatTen(month) + "-" + this.formatTen(day) + " " + this.formatTen(h) + ':' + this.formatTen(m) + ":" + this.formatTen(s);
      }
      else{
        return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
      }
    },
    //改变月
    ChangeMonthvalue() {
      this.getMonthDate(this.Monthvalue)
    },
  }
}
</script>
<style lang="less" scoped>
.top{
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
}
</style>